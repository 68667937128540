import OrderCategoryAndConstructionsSite from "./OrderCategoryAndConstructionsSite";

const CreateOrder = () => {

    return (
        <>
            <OrderCategoryAndConstructionsSite/>
        </>
    )

}

export default CreateOrder;